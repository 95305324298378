import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';
import clsx from 'clsx';
import useKey from '../Lib/useKey';

const Popup = ({
                 children, withoutCloseBtn, variant, onClosePopup, className,
               }) => {
  const poupRef = useRef();
  const insideRef = useRef();

  const doClosePopup = () => {
    onClosePopup();
    poupRef.current.classList.add('stdPopup-disappear');
  };

  useKey({
    keyCode: 27,
    callback: doClosePopup,
  });

  const handleClick = (e) => {
    if (insideRef.current) {
      if (!insideRef.current.contains(e.target)) {
        onClosePopup();
      }
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  useEffect(() => {
    if (poupRef.current) {
      poupRef.current.addEventListener('animationend', (e) => {
        if (e.target === poupRef.current && e.animationName === 'fade-reverse') {
          onClosePopup();
        }
      });
    }
  });

  return ReactDOM.createPortal(
    <div
      className={clsx(
        'stdPopup',
        'stdPopup-appear',
        variant && variant.split(' ').map((el) => `stdPopup-${el}`),
        className,
      )}
      ref={poupRef}
    >
      <div ref={insideRef}>
        <button type="button" onClick={() => onClosePopup()}>X</button>
        { children }
      </div>
    </div>, document.getElementById('popupContainer'),
  );
};

Popup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  withoutCloseBtn: PropTypes.bool,
  onClosePopup: PropTypes.func.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};

Popup.defaultProps = {
  icon: null,
  withoutCloseBtn: false,
  children: '',
  className: null,
  variantClose: null,
  variant: null,
};

export default Popup;
