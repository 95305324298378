import React, { useState } from 'react';
import { clsx } from 'clsx';

const Desc = () => {
  const [lang, setLang] = useState('fr');
  return (
    <>
      { lang === 'fr' ? (
        <div className="page-accueil-part2-fr">
          <p>
            Oh ! Mais tiens ? J'ai l'impression que c'est la FÊTE ! <br/>
            OUI ! Le Gumbo est dans les parages, et les oreilles des chalants frétillent devant ce son de la Nouvelle Orléans. <br/>
            Les cuivres virevoltent dans une gentille parade hardcore, YOUPI !
          </p>
          <p>
            Si vous avez les guibolles qui flageollent, la pétrolette en cacahouète, le haricot qu'est plus dans le lot, procurez vous IMMÉDIATEMENT une cure de Gumbo. <br/>
            Ce groupe sans excipients notoires vous permettra de retrouver l'âme frère-sœur, la réussite aux examens et l'ASSURANCE du gros lot à l'euromillion. <br/>
            Si votre clown de poche ou votre console vous a fait faux bond, n'hésitez pas à inviter ce groupe à vos thés d'anniversaire, l'effet sera GARANTI. <br/>
            Les musiciens du Gumbo vous combleront tout ce qui peut vous manquer et votre TRAIN-TRAIN quotidien s'enjaillera d'autant plus. <br/>
            L'office du tourisme de Saint-Germain-de-Tallevende-la-Lande-Vaumont décline toute RESPONSABILITÉ en cas de dérapage.
          </p>
          <p>
            Le Gumbo est une fanfare lyonnaise, opérant dans le style swing traditionnel de la Nouvelle Orléans, compétente en anniversaires, mariages, concerts, parades, fêtes de village et toutes autres réjouissances.
          </p>
          <p>
            VIVE LA FÊTE !
          </p>
        </div>
      ) : (
        <div className="page-accueil-part2-en">
          <p>
            Oh, my! I feel like it's a PARTY!  <br/>
            YES! The Gumbo is around, and the ears of the chalants are wiggling to the sound of New Orleans. <br/>
            The horns twirl in a gentle hardcore parade, YOUPI!
          </p>
          <p>
            If you've got wobbly shins, a peanut-ridden petrolette or a bean that's out of place, get yourself a Gumbo cure IMMEDIATELY. <br/>
            This group, with no known excipients, will help you find your soul mate, pass your exams and be INSURED of a Euromillion jackpot.<br/>
            If your pocket clown or console has failed you, don't hesitate to invite this group to your birthday teas - the effect is GUARANTEED. <br/>
            The Gumbo's musicians will fill you up on everything you're missing, making your daily TRAIN-TRAIN all the more enchanting. <br/>
            The Saint-Germain-de-Tallevende-la-Lande-Vaumont tourist office declines all RESPONSIBILITY in the event of a slip-up.
          </p>
          <p>
            Le Gumbo is a brass band from Lyon, France, operating in the traditional swing style of New Orleans, skilled in birthdays, weddings, concerts, parades, village festivals and all other festivities.
          </p>
          <p>
            LONG LIVE THE PARTY!
          </p>
        </div>
      )}

      {/*<button className={clsx(lang === 'en' && 'en')} onClick={() => setLang(lang === 'fr' ? 'en' : 'fr')}>&nbsp;</button>*/}
    </>
  );
};

export default Desc;
