import React from 'react';
import facebook from '../../img/facebook.svg';
import instagram from '../../img/instagram.svg';
import youtube from '../../img/youtube.svg';
import spotify from '../../img/spotifySmall.svg';
import deezer from '../../img/deezerSmall.svg';

const Social = () => (
  <nav className="socialMenu">
    <ul>
      <li><a href="https://www.facebook.com/gumboswings" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a></li>
      <li><a href="https://www.instagram.com/le.gumbo/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram" /></a></li>
    </ul>
  </nav>
);

export default Social;
