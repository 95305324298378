import React, { useState } from 'react';
import photo1 from '../../img/Photos/photo1.jpg';
import photo2 from '../../img/Photos/photo2.jpg';
import photo3 from '../../img/Photos/photo3.jpg';
import photo4 from '../../img/Photos/photo4.jpg';
import photo5 from '../../img/Photos/photo5.jpg';
import photo6 from '../../img/Photos/photo6.jpg';
import photo7 from '../../img/Photos/photo7.jpg';
import photo8 from '../../img/Photos/photo8.jpg';
import photo9 from '../../img/Photos/photo9.jpg';
import photo10 from '../../img/Photos/photo10.jpg';
import photo11 from '../../img/Photos/photo11.jpg';
import photo12 from '../../img/Photos/photo12.jpg';
import photo13 from '../../img/Photos/photo13.jpg';
import Popup from './Popup';

const Gallery = () => {
  const [curPage, setCurPage] = useState(0);
  const [src, setSrc] = useState(null);

  return (
    <div className="photosContainer carousel" data-slide="0">
      { src !== null && (
      <Popup onClosePopup={() => setSrc(null)} className="photosContainer-popup">
        <img src={src} />
      </Popup>
      )}
      { curPage > 0 && <button className="previousBtn" onClick={() => setCurPage(curPage - 1)} />}
      <div>
        <div>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo1)}><img src={photo1} alt="Le saxophone joue devant des gens qui dansent" /></button>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo2)}><img src={photo2} alt="La saxophoniste parle de la fanfare new orleans au micro" /></button>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo3)}><img src={photo3} alt="Le tromboniste aime le jazz" /></button>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo4)}><img src={photo4} alt="La trompettiste improvise pour un mariage" /></button>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo5)}><img src={photo5} alt="Le saxophone baryton joue devant des gens qui dansent" /></button>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo6)}><img src={photo6} alt="Le saxophone baryton joue devant des gens qui dansent" /></button>
        </div>
        <div>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo7)}><img src={photo7} alt="Le tromboniste aime le jazz" /></button>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo8)}><img src={photo8} alt="La trompettiste improvise pour un mariage" /></button>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo9)}><img src={photo9} alt="Le saxophone baryton joue devant des gens qui dansent" /></button>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo10)}><img src={photo10} alt="La saxophoniste parle de la fanfare new orleans au micro" /></button>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo11)}><img src={photo11} alt="Le tromboniste aime le jazz" /></button>
          <button style={{ position: 'relative', left: `-${100 * curPage}%` }} onClick={() => setSrc(photo12)}><img src={photo12} alt="La trompettiste improvise pour un mariage" /></button>
        </div>
      </div>

      { curPage < 1 && <button className="nextBtn" onClick={() => setCurPage(curPage + 1)} /> }
    </div>
  );
};

export default Gallery;
