import React from 'react';
import Concerts from './Concerts';
import VideoCarousel from './VideoCarousel';
import Gallery from './Gallery';
import videoFallback from '../../video/videoFallback.jpg';
import video720p from '../../video/highTraite.mp4';
import logo from '../../img/logo.png';
import Social from './Social';
import Desc from "./Desc";

const Accueil = () => {

  const scrollTo = (id) => {
    const section = document.querySelector(id);
    section.scrollIntoView({behavior: 'smooth', block: 'start'});
  };


  return (
    <>

      <div id="page-accueil">

        <header>
          <nav>
            <ul>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part2')}>Accueil</button>
              </li>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part3')}>Medias</button>
              </li>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part4')}>Dates</button>
              </li>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part5')}>Contact</button>
              </li>
            </ul>
          </nav>

          <Social/>

        </header>

        <section id="page-accueil-part1">
          <img id="page-accueil-part1-logo" src={logo}/>

          <div className="videoContainer">
            <div className="videoContainer-overlay"/>
            <img src={videoFallback} alt="Le gumbo joue du New Orleans"/>
            <video loop playsInline autoPlay muted>
              <source src={video720p} type="video/mp4"/>
            </video>
          </div>
          <div className="gradienBot"/>
        </section>

        <section id="page-accueil-part2">
          <Desc/>

        </section>

        <section id="page-accueil-part3">
          <div className="gradienTop"/>
          <div className="gradienBot"/>
          <div>
            <h2 className="stdTitle"><span>M</span>édias</h2>

            <div>
              <VideoCarousel/>
            </div>

            <Gallery/>

          </div>
        </section>
        <section id="page-accueil-part4">

          <h2 className="stdTitle"><span>D</span>ates</h2>

          <Concerts/>

        </section>
        <section id="page-accueil-part5">
          <div>
            <div>
              <h3>Contact / Booking : <small>contact@legumbo.fr</small></h3>
            </div>
            <div>
              <h3>Social</h3>
              <Social/>

            </div>
          </div>
        </section>

        {/*<footer>*/}
        {/*  <nav>*/}
        {/*    <ul>*/}
        {/*      <li><a href="">copyright</a></li>*/}
        {/*      <li><a href="">mentions légales</a></li>*/}
        {/*    </ul>*/}
        {/*  </nav>*/}
        {/*</footer>*/}

      </div>
    </>
  );
};

export default Accueil;
