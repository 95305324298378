import React, { useState } from 'react';
import Popup from './Popup';
import jelly from '../../img/video_jelly.jpg';
import intro from '../../img/video_intro.jpg';
import tiger from '../../img/video_tiger.jpg';
import playbtn from '../../img/playbtn.svg';

const VideoCarousel = () => {
  const [curPage, setCurPage] = useState(0);
  const [curSelectedVideoCode, setCurSelectedVideoCode] = useState(null);

  return (
    <>
      { curSelectedVideoCode && (
      <Popup onClosePopup={() => setCurSelectedVideoCode(null)}>
        <iframe className="smallVideoContainer-iframe" src={`https://www.youtube.com/embed/${curSelectedVideoCode}?autoplay=1&showinfo=0&modestbranding=1&&rel=0&wmode=transparent&autohide=1`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </Popup>
      )}

      <div className="smallVideoContainer carousel" data-slide="0">
        { curPage > 0 && <button className="previousBtn" onClick={() => setCurPage(curPage - 1)} />}
        <div>
          <div style={{ position: 'relative', left: `-${100 * curPage}%` }}>
            <button className="smallVideo" onClick={() => setCurSelectedVideoCode('GFBUn5xXGzQ')}>
              <img src={jelly} alt="la Video de Mahadeva psytrance astral projection" />
              <img src={playbtn} alt="la Video de Mahadeva psytrance astral projection" />
            </button>
            <div>Le Gumbo - Jelly Roll</div>
          </div>

          <div style={{ position: 'relative', left: `-${100 * curPage}%` }}>
            <button className="smallVideo" onClick={() => setCurSelectedVideoCode('VuX4j9k4HKw')}>
              <img src={intro} alt="la Video de Darius en live" />
              <img src={playbtn} alt="la Video de Darius en live" />
            </button>
            <div>Le Gumbo - Intro</div>
          </div>

          <div style={{ position: 'relative', left: `-${100 * curPage}%` }}>
            <button className="smallVideo" onClick={() => setCurSelectedVideoCode('UU07-TlLIPc')}>
              <img src={tiger} alt="la Video de movement of whale deep house" />
              <img src={playbtn} alt="la Video de movement of whale deep house" />
            </button>
            <div>Le Gumbo - Tiger Rag</div>
          </div>
        </div>
        { curPage < 2 && <button className="nextBtn" onClick={() => setCurPage(curPage + 1)} /> }
      </div>
    </>
  );
};

export default VideoCarousel;
